import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      searchFilterOrder: 0,
      pageTitle: 'module.cooperation-provider',
      search: 'cooperation-provider.search_keyword',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      // @ts-ignore
      listItem: () => import('@/modules/store/views/cooperationItem/liffcooperationItem.vue'),
    }

  }
}

export default new listConfig()
