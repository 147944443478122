<template>
  <section>
    <v-container d-flex justify-space-between align-center pb-0 pt-2>
      <page-title :title="title" />
      <v-btn v-if="isShowApply" color="primary" small @click="goApply">申請</v-btn>
    </v-container>
    <liff-list :listKey="listKey"></liff-list>
  </section>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./cooperationList"

export default {
  mixins: [listMixin],
  components: {

  },
  data: () => ({
    listKey: 'liff-cooperation-list',
    meta: {},
    isShowApply: false,
  }),
  computed: {
    title() {
      return this.$t(`cooperation-provider`)
    },
    providerId() {
      return this.$route.params.providerId
    },
    storeId() {
      return this.$store.getters[`member/storeId`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    status() {
      if(!this.listFilter) return null
      return this.listFilter.status
    },
  },
  methods: { 
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      const res = await this.$api.collection.storeApi.coProviderIndex(this.storeId, params)
      if (res && res.data) {
        const providerIdList = res.data.map(v => v.providerId)
        this.isShowApply = !providerIdList.includes(this.providerId)
      }
      return res
    },
    getListConfig() {
      return listConfig
    },
    goApply() {
      this.$router.push({name: 'store-register', params: {providerId: this.providerId}})
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
